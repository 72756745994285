<template>
  <b-card>
    <!-- Logo -->
    <logo dark />

    <!-- Alert -->
    <b-alert
      v-if="alert"
      :show="true"
      :variant="alert.variant || 'danger'"
    >
      <h4 class="alert-heading">
        {{ alert.title }}
      </h4>
    </b-alert>

    <!-- Header -->
    <b-card-title class="mb-1">
      <slot name="title" />
    </b-card-title>

    <b-card-text class="mb-2">
      <slot name="subtitle" />
    </b-card-text>

    <!-- Form -->
    <validation-observer
      ref="form"
      v-slot="{ invalid, handleSubmit }"
    >
      <b-form
        class="auth-form mt-2"
        @submit.prevent="handleSubmit($emit('submit'))"
      >

        <slot name="form" />

        <submit-button
          :is-loading="loading"
          :invalid="invalid"
          block
          class="mt-2"
        >
          <slot name="button-text" />
        </submit-button>

      </b-form>
    </validation-observer>

    <!-- Footer -->
    <b-card-text class="text-center mt-2">
      <slot name="footer" />
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BAlert,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
} from 'bootstrap-vue'
import SubmitButton from '@/components/buttons/ButtonLoading.vue'
import { ValidationObserver } from 'vee-validate'
import Logo from '@/components/Logo.vue'

export default {
  components: {
    BAlert,
    Logo,
    BForm,
    BCard,
    BCardTitle,
    BCardText,
    SubmitButton,
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    alert: {
      type: Object,
    },
  },
}
</script>
